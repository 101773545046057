import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import axios from "axios"
// import "toastr"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import { useEffect } from "react"
import { AddQuery, DeleteQuery, GetQuery, UpdateQuery } from "QueryApi/Reactapi"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"
import { useUserContext } from "ContextApi/Context"


// import { reset } from "redux-form"
function Users() {
  const { userId } = useUserContext()
  // console.log("userId", userId)

  const [classtoggle, setclasstoggle] = useState(false)
  const [update, setupdate] = useState({})
  const [ClassALERT, setClassALERT] = useState(false)
  const [Cid, setCid] = useState("")
  const [deleteID, setdeleteID] = useState("")
  const [statusID, setstatusID] = useState("")
  const [getclasses, setgetclasses] = useState([])

  const handleclasstoggle = () => {
    setclasstoggle(!classtoggle)
  }
  const handlenewIcon = () => {
    handleclasstoggle()
    setCid("")
    setupdate("")
  }
  //get class data from useefect
  useEffect(() => {
    async function onlood() {
      const token = localStorage.getItem("token")
      const headers = {
        headers: {
          "Content-type": "application/json",
          Authorization: `Bearer ${token}`,
        },
      }
      const { data } = await axios.get(
        "https://macalinkaal-api.vercel.app/class",
        headers
      )
      // console.log(data)
      setgetclasses(data)
    }
    onlood()
  }, [])
  // get data class
  const { data: userdata } = GetQuery("/users", "users")
  // console.log(classdata)
  // add new class
  const { mutateAsync, isLoading } = AddQuery("/users", "users")
  //update query
  const { mutateAsync: up, isLoading: updateloading } = UpdateQuery(
    "/users",
    Cid,
    "users"
  )

  // post data
  const handleClasspost = (event, value) => {
    try {
      if (Cid !== "") {
        const data = {
          password: value.password,
          name: value.name,
          email: value.email,
        }
        up(data).then(res => {
          let message = res?.data?.message
          Swal.fire("success!", message, "success")
       
          handleclasstoggle()
          // reset()
        })
      } else {
        const data = {
          password: value.password,
          name: value.name,
          email: value.email,
          classID: value.classID ? value.classID : "--",
          Role: value.Role ? value.Role : "Student",
        }
        // return console.log(data)
        mutateAsync(data).then(res => {
          let message = res?.data?.message
          Swal.fire("success!", message, "success")
        

          // alert("class created successfully")
          handleclasstoggle()
        })
      }
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log(value)
  }
  // update class
  const updatclassData = data => {
    setupdate(data)
    setCid(data._id)
    handleclasstoggle()
  }
  // delete class
  const { mutateAsync: deletemuate } = DeleteQuery("/users", "users")
  const handledelete = data => {
    setdeleteID(data._id)
    setClassALERT(true)
  }
  const deleteallprice = () => {
    try {
      deletemuate(deleteID).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        setClassALERT(false)
      })
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log("first delete")
  }
  const { mutateAsync: updatemuate } = UpdateQuery(
    "users/update",
    statusID,
    "users"
  )
  // status
  const handlestatus = (e, data) => {
    setstatusID(data._id)
    // console.log(data)
    if (e.target.checked == true) {
      updatemuate({ status: "active" }).then(() => {
        // let message = res?.data?.message
        Swal.fire("success!", ` ${data?.name} has been just actived`, "success")
        // alert("user has been just active")
      })
    } else {
      updatemuate({ status: "pending" }).then(() => {
        Swal.fire("success!", ` ${data?.name} has been just pended`, "success")

        // alert("user has been just pending")
      })
    }
    // console.log(data._id)
  }
  const data = {
    columns: [
      {
        label: "name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "classID",
        field: "classID",
        sort: "asc",
        width: 150,
      },

      {
        label: "email",
        field: "email",
        sort: "asc",
        width: 150,
      },
      {
        label: "status",
        field: "status",
        sort: "asc",
        width: 350,
      },
      {
        label: "Role",
        field: "Role",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: userdata?.map(data => {
      // if (data?.Role === "admin") {
      //   return data.Role === "Student"
      // }
      data.classID = data.classID?.ClassName

      data.action = (
        <div>
          <button
            className="btn   "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => updatclassData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>

          <button
            className="btn   mx-3 "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => handledelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      data.status = (
        <div class="form-check">
          {data.status}
          {data.Role !== "admin" ? (
            <>
              {data.Role !== "Teacher" && (
                <input
                  class="form-check-input"
                  type="checkbox"
                  value="status"
                  id="flexCheckChecked"
                  // checked
                  checked={data?.status === "active" ? true : false}
                  onChange={e => handlestatus(e, data)}
                />
              )}
            </>
          ) : null}
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
     
      <div className="page-content">
        <MetaTags>
          <title>Class Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="Student"
          />

          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">CLASS LIST </CardTitle> */}
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <Row>
                        <Col sm={6} md={4} xl={3}>
                          <div className="my-3 text-center">
                            <button
                              type="button"
                              onClick={() => {
                                handlenewIcon()
                              }}
                              className="btn  waves-effect waves-light"
                              style={{
                                backgroundColor: "#E43825",
                                color: "white",
                              }}
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              <i className="ion ion-md-add"> </i>
                            </button>
                            <Modal
                              isOpen={classtoggle}
                              toggle={() => {
                                handleclasstoggle()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Modal Heading
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleclasstoggle()
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  handleClasspost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Name
                                        </Label>
                                        <AvField
                                          name="name"
                                          value={update.name}
                                          placeholder="name"
                                          type="text"
                                          errorMessage="Enter name"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          classID
                                        </Label>
                                        <AvField
                                          name="classID"
                                          type="select"
                                          value={update.classID}
                                          //   editDefaultValue={update.classID}
                                          //   onChange={e =>
                                          //     setpricepost({
                                          //       ...pricepost,
                                          //       customerID: e.target.value,
                                          //     })
                                          //   }

                                          className="form-control"
                                          id="validationCustom02"
                                        >
                                          <option value="">
                                            {" "}
                                            select className
                                          </option>
                                          {getclasses.map(classes => {
                                            return (
                                              <option value={classes._id}>
                                                {" "}
                                                {classes.ClassName}
                                              </option>
                                            )
                                          })}
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          email
                                        </Label>
                                        <AvField
                                          name="email"
                                          value={update.email}
                                          placeholder="email"
                                          type="email"
                                          errorMessage="Enter email"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          password
                                        </Label>
                                        <AvField
                                          name="password"
                                          value={update.password}
                                          placeholder="password"
                                          type="password"
                                          errorMessage="Enter password"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    {/* <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Role
                                        </Label>
                                        <AvField
                                          name="Role"
                                          type="select"
                                          //   value={update.classID}
                                          //   editDefaultValue={update.classID}
                                          //   onChange={e =>
                                          //     setpricepost({
                                          //       ...pricepost,
                                          //       customerID: e.target.value,
                                          //     })
                                          //   }
                                          // errorMessage="Enter Role"
                                          className="form-control"
                                          // validate={{
                                          //   required: { value: true },
                                          // }}
                                          id="validationCustom02"
                                        >
                                          <option value="">
                                            {" "}
                                            select className
                                          </option>
                                          <option value="admin"> admin</option>
                                          <option value="Student">
                                            {" "}
                                            Student
                                          </option>
                                          <option value="Teacher">
                                            Teacher
                                          </option>
                                        </AvField>
                                      </FormGroup>
                                    </Col> */}
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleclasstoggle()
                                    }}
                                    className="btn  waves-effect"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    disabled={
                                      Cid !== "" ? updateloading : isLoading
                                    }
                                    className="btn  waves-effect waves-light"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {ClassALERT ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Users
