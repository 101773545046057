const { createContext, useEffect, useState, useContext } = require("react")
import axios from "axios"
import jwtDecode from "jwt-decode"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const userContextApi = createContext()

export const UserContextProvider = ({ children }) => {
  let history = useHistory()
  const [email, setemail] = useState("")
  const [RoleAllowed, setRoleAllowed] = useState("")
  const [getclassdata, setgetclassdata] = useState("")
  const [getuserstudent, setgetuserstudent] = useState({})
  const [userId, setuserid] = useState("")

  // const LOGOUT = () => {
  //   console.log("logged out")
  // }

  async function onlood() {
    const token = localStorage.getItem("token")
    if (!token) {
      history.push("/login")
    } else {
      //   console.log(jwtDecode(token))
      const jwtdecode = jwtDecode(token)
      //   setemail(jwtDecode.name)
      const { data } = await axios.get(
        `https://macalinkaal-api.vercel.app/users/${jwtdecode.id}`
      )
      setRoleAllowed(data.Role)
      setemail(data.name)
      setgetclassdata(data.classID)
      setgetuserstudent(data.classID)
      setuserid(data._id)

      // console.log(data)
    }
  }
  useEffect(() => {
    onlood()
  }, [])

  return (
    <userContextApi.Provider
      value={{
        onlood,
        RoleAllowed,
        email,
        setemail,
        getclassdata,
        getuserstudent,
        userId,
      }}
    >
      {children}
    </userContextApi.Provider>
  )
}

export const useUserContext = () => {
  return useContext(userContextApi)
}
