import React from "react"
import { Redirect } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"
import ClassComp from "../pages/class/classComp"
import ActivityReport from "../pages/reportByActivity/ActivityReport"

// Dashboard
import Dashboard from "../pages/Dashboard/index"
import Student from "pages/Students/Student"
import Marks from "pages/Marks/Marks"
import ReportMarks from "pages/ReportMarks/ReportMarks"
import Activity from "pages/Activity/Activity"
import personalReportMarks from "pages/PersonalReport/PersonalReport"
import Users from "pages/Users/Users"
import SubMarks from "pages/Submarks/Submarks"
import Upload from "pages/marge/marge"
import maclinkaalForm from "pages/macalinkaalForm/formComp"

const userRoutes = [
  { path: "/dashboard", component: Dashboard },
  { path: "/class", component: ClassComp },
  { path: "/student", component: Student },
  { path: "/marks", component: Marks },
  { path: "/Report", component: ReportMarks },
  { path: "/Activity", component: Activity },
  { path: "/personalReport", component: personalReportMarks },
  { path: "/SubMarks", component: SubMarks },
  { path: "/ActivityReport", component: ActivityReport },
  { path: "/maclinkaalForm", component: maclinkaalForm },
  { path: "/Users", component: Users },
  { path: "/marge", component: Upload },

  // // //profile
  { path: "/profile", component: UserProfile },

  // this route should be at the end of all other routes
  { path: "/", exact: true, component: () => <Redirect to="/login" /> },
]

const authRoutes = [
  { path: "/logout", component: Logout },
  { path: "/login", component: Login },
  { path: "/forgot-password", component: ForgetPwd },
  { path: "/register", component: Register },
]

export { userRoutes, authRoutes }
