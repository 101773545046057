import React, { useState, useEffect } from "react";
import MetaTags from "react-meta-tags";
import { MDBDataTable } from "mdbreact";
import { Col, Row, Card, CardBody, Modal, FormGroup, Label, Button, Input } from "reactstrap";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import Swal from "sweetalert2/dist/sweetalert2.js";
import "sweetalert2/src/sweetalert2.scss";
import moment from "moment";
import * as XLSX from 'xlsx';
import { AddQuery, DeleteQuery, GetQuery, UpdateQuery } from "QueryApi/Reactapi";
import SweetAlert from "react-bootstrap-sweetalert";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

function ClassComp() {
  const [classtoggle, setclasstoggle] = useState(false);
  const [ClassALERT, setClassALERT] = useState(false);
  const [updateModal, setUpdateModal] = useState(false);
  const [deleteID, setdeleteID] = useState("");
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [excelData, setExcelData] = useState([]);
  const [dataForDownload, setDataForDownload] = useState([]);
  const [updatedData, setUpdatedData] = useState({
    Name: '',
    Gander: '',
    number: '',
    Number: '',
    // Email: ''
  });

  const handleclasstoggle = () => {
    setclasstoggle(!classtoggle);
  };

  const { data: classdata } = GetQuery("/ssi", "ssi");
  
  useEffect(() => {
    if (classdata) {
      // setExcelData(classdata);
      const formattedData = classdata.map(item => ({
        Name: item.Name,
        Gander: item.Gander,
        'Phone number': item.number,
        'Serial Number': item.Number,
        // Email: item.Email,
      }));
      setDataForDownload(formattedData);
    }
  }, [classdata]);

  const { mutateAsync, isLoading } = AddQuery("/ssi/createMany", "ssi");

  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const validExtensions = ['xlsx', 'xls'];
    const fileExtension = file.name.split('.').pop();

    if (!validExtensions.includes(fileExtension)) {
      Swal.fire("Error", "Please upload a valid Excel file.", "error");
      return;
    }

    const reader = new FileReader();

    reader.onload = (event) => {
      const data = new Uint8Array(event.target.result);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];
      const json = XLSX.utils.sheet_to_json(worksheet);
      setExcelData(json);
    };

    reader.readAsArrayBuffer(file);
  };

  const handleFileSubmit = async () => {
    try {
      if (excelData.length) {
        await mutateAsync(excelData).then(async(res)=>{
          console.log("res",await res)
        }).catch((err)=>{
          console.log("err",err)
        });
        handleclasstoggle();
      } else {
        Swal.fire("Error", "No data to submit.", "error");
      }
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const { mutateAsync: deletemuate } = DeleteQuery("/ssi", "ssi");
  const { mutateAsync: deleteAll } = AddQuery("/deleteMany", "ssi");

  const handledelete = (data) => {
    setdeleteID(data._id);
    setClassALERT(true);
  };

  const deleteallprice = async () => {
    try {
      await deletemuate(deleteID);
      Swal.fire("Success!", "Data deleted successfully", "success");
      setClassALERT(false);
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleReset = async () => {
    try {
      await deleteAll();
      Swal.fire("Success!", "Data deleted successfully", "success");
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const handleUpdateToggle = (data) => {
    setUpdateModal(!updateModal);
    if (data) {
      setSelectedRecord(data);
      setUpdatedData({
        Name: data.Name,
        Gander: data.Gander,
        number: data.number,
        Number: data.Number,
        // Email: data.Email
      });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setUpdatedData({
      ...updatedData,
      [name]: value
    });
  };

  const { mutateAsync: updateMutate } = UpdateQuery("/ssi", selectedRecord?._id, "ssi");

  const handleUpdateSubmit = async () => {
    try {
      await updateMutate(updatedData);
      Swal.fire("Success!", "Data updated successfully", "success");
      handleUpdateToggle();
    } catch (error) {
      Swal.fire("Error", error.message, "error");
    }
  };

  const data = {
    columns: [
      { label: "name", field: "Name", sort: "asc", width: 150 },
      { label: "Gendar", field: "Gander", sort: "asc", width: 150 },
      { label: "phone", field: "number", sort: "asc", width: 150 },
      { label: "Serial number", field: "Number", sort: "asc", width: 150 },
      { label: "Email", field: "Email", sort: "asc", width: 150 },
      { label: "Date", field: "createdAt", sort: "asc", width: 150 },
      { label: "Action", field: "action", sort: "asc", width: 150 },
    ],
    rows: classdata?.map(data => {
      data.createdAt = moment(data.createdAt).format('YYYY-MM-DD');
      data.action = (
        <div>
          <button
            className="btn mx-3"
            style={{ color: "#E43825" }}
            onClick={() => handledelete(data)}
          >
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
          <button
            className="btn mx-3"
            style={{ color: "#007bff" }}
            onClick={() => handleUpdateToggle(data)}
          >
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
        </div>
      );
      return data;
    }),
  };

  const downloadAllData = () => {
    const worksheet = XLSX.utils.json_to_sheet(dataForDownload);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");
    XLSX.writeFile(workbook, "table_data.xlsx", { bookType: 'xlsx' });
};
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="Form"
          />
          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  <div style={{display:"flex",justifyContent:"space-between",padding:"0px 20px"}}>
                    <Button
                      type="button"
                      onClick={handleclasstoggle}
                      className="btn btn-outline-light waves-effect waves-light"
                      style={{ backgroundColor: "#E43825", color: "white" }}
                    >
                      Upload Excel File
                    </Button>

                    <Button
                      type="button"
                      onClick={handleReset}
                      className="btn btn-outline-light waves-effect waves-light"
                      style={{ backgroundColor: "red", color: "white" }}
                    >
                      Reset data
                    </Button>
                  </div>
                  <Modal isOpen={classtoggle} toggle={handleclasstoggle}>
                    <div className="modal-header">
                      <h5 className="modal-title mt-0" id="myModalLabel">
                        Upload Excel File
                      </h5>
                      <button
                        type="button"
                        onClick={handleclasstoggle}
                        className="close"
                        data-dismiss="modal"
                        aria-label="Close"
                      >
                        <span aria-hidden="true">&times;</span>
                      </button>
                    </div>
                    <div className="modal-body">
                      <FormGroup className="mb-3">
                        <Label htmlFor="excelFile">Select Excel File</Label>
                        <input
                          type="file"
                          className="form-control"
                          id="excelFile"
                          onChange={handleFileUpload}
                          accept=".xlsx,.xls"
                        />
                      </FormGroup>
                    </div>
                    <div className="modal-footer">
                      <Button
                        type="button"
                        onClick={handleclasstoggle}
                        className="btn waves-effect"
                        style={{ backgroundColor: "#E43825", color: "white" }}
                        data-dismiss="modal"
                      >
                        Close
                      </Button>
                      <Button
                        type="button"
                        onClick={handleFileSubmit}
                        disabled={isLoading}
                        className="btn waves-effect waves-light"
                        style={{ backgroundColor: "#E43825", color: "white" }}
                      >
                        Submit
                      </Button>
                    </div>
                  </Modal>
                  <Button
                    className="btn waves-effect  bg-success text-white"
                    onClick={downloadAllData}
                  >
                    Get all data as Excel
                  </Button>
                  <MDBDataTable  id="table-to-xls"  responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {ClassALERT && (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          )}
          <Modal isOpen={updateModal} toggle={() => handleUpdateToggle()}>
            <div className="modal-header">
              <h5 className="modal-title mt-0" id="myModalLabel">
                Update Record
              </h5>
              <button
                type="button"
                onClick={() => handleUpdateToggle()}
                className="close"
                data-dismiss="modal"
                aria-label="Close"
              >
                <span aria-hidden="true">&times;</span>
              </button>
            </div>
            <div className="modal-body">
              <FormGroup className="mb-3">
                <Label for="Name">Name</Label>
                <Input
                  type="text"
                  name="Name"
                  id="Name"
                  value={updatedData.Name}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="Gander">Gender</Label>
                <Input
                  type="text"
                  name="Gander"
                  id="Gander"
                  value={updatedData.Gander}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="number">Phone</Label>
                <Input
                  type="text"
                  name="number"
                  id="number"
                  value={updatedData.number}
                  onChange={handleInputChange}
                />
              </FormGroup>
              <FormGroup className="mb-3">
                <Label for="Number">Serial Number</Label>
                <Input
                  type="text"
                  name="Number"
                  id="Number"
                  value={updatedData.Number}
                  onChange={handleInputChange}
                />
              </FormGroup>
              {/* <FormGroup className="mb-3">
                <Label for="Email">Email</Label>
                <Input
                  type="email"
                  name="Email"
                  id="Email"
                  value={updatedData.Email}
                  onChange={handleInputChange}
                />
              </FormGroup> */}
            </div>
            <div className="modal-footer">
              <Button
                type="button"
                onClick={() => handleUpdateToggle()}
                className="btn waves-effect"
                style={{ backgroundColor: "#E43825", color: "white" }}
                data-dismiss="modal"
              >
                Close
              </Button>
              <Button
                type="button"
                onClick={handleUpdateSubmit}
                disabled={isLoading}
                className="btn waves-effect waves-light"
                style={{ backgroundColor: "#E43825", color: "white" }}
              >
                Update
              </Button>
            </div>
          </Modal>
        </div>
      </div>
    </React.Fragment>
  );
}

export default ClassComp;
