import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import axios from "axios"
// import "toastr"
import { useEffect } from "react"
import { AddQuery, DeleteQuery, GetQuery, UpdateQuery } from "QueryApi/Reactapi"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import { useUserContext } from "ContextApi/Context"

// import { reset } from "redux-form"
function Student() {
  const { getclassdata, RoleAllowed } = useUserContext()
  const [classtoggle, setclasstoggle] = useState(false)
  const [update, setupdate] = useState({})
  const [ClassALERT, setClassALERT] = useState(false)
  const [sid, setsid] = useState("")
  const [deleteID, setdeleteID] = useState("")
  const [getclasses, setgetclasses] = useState([])
  const handleSTUDENTtoggle = () => {
    setclasstoggle(!classtoggle)
  }
  const handlenewIcon = () => {
    handleSTUDENTtoggle()
    setsid("")
    setupdate("")
  }
  // console.log(getclassdata?._id)
  // get data students
  const { data: studentdata } = GetQuery("/marks/userbyclass", "student")
  //   console.log(studentdata)

  //get class data from useefect
  useEffect(() => {
    async function onlood() {
      const { data } = await axios.get(
        "https://macalinkaal-api.vercel.app/class"
      )
      // console.log(data)
      setgetclasses(data)
    }
    onlood()
  }, [])

  // console.log(classdata)
  // add new class
  const { mutateAsync, isLoading } = AddQuery("/student", "student")
  //   //update query
  const { mutateAsync: updatemuate, isLoading: updateloading } = UpdateQuery(
    "/student",
    sid,
    "student"
  )

  // post data
  const handleStudendpost = async (event, value) => {
    if (sid !== "") {
      // return console.log(value)
      // const data = {
      //   StudentName: value.StudentName,

      //   classID: value.classID,
      //   StudentID: value.StudentID,
      // }

      console.log(data)
      updatemuate(value).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        // alert("student has been updated")
        handleSTUDENTtoggle()
        // reset()
      })
    } else {
      mutateAsync(value).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        // toastr.success("class created successfully")
        // alert("student created successfully")
        handleSTUDENTtoggle()
      })
    }
    // console.log(value)
  }
  //update class
  const updatestudentData = data => {
    // console.log(data)
    setupdate(data)
    setsid(data._id)
    handleSTUDENTtoggle()
  }
  // delete class
  const { mutateAsync: deletemuate } = DeleteQuery("/student", "student")
  const handledelete = data => {
    setdeleteID(data._id)
    setClassALERT(true)
  }
  const deleteallprice = () => {
    try {
      deletemuate(deleteID).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        // alert("successfully deleted ")
        setClassALERT(false)
      })
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log("first delete")
  }
  const data = {
    columns: [
      {
        label: "ClassName",
        field: "className",
        sort: "asc",
        width: 150,
      },
      {
        label: "StudentID",
        field: "StudentID",
        sort: "asc",
        width: 150,
      },
      {
        label: "StudentName",
        field: "StudentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "TotalMarks",
        field: "TotalMarks",
        sort: "asc",
        width: 150,
      },
      // {
      //   label: "Activity",
      //   field: "Activity",
      //   sort: "asc",
      //   width: 150,
      // },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 450,
      },
    ],
    rows: studentdata?.map(data => {
      data.className = data.classID?.ClassName

      data.action = (
        <div>
          <button
            className="btn  "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => updatestudentData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button>
          <button
            className="btn  "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => handledelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Class Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="Student"
          />
          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">CLASS LIST </CardTitle> */}
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <Row>
                        <Col sm={6} md={4} xl={3}>
                          <div className="my-3 text-center">
                            <button
                              type="button"
                              onClick={() => {
                                handlenewIcon()
                              }}
                              className="btn  waves-effect waves-light"
                              style={{
                                backgroundColor: "#E43825",
                                color: "white",
                              }}
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              <i className="ion ion-md-add"> </i>
                            </button>
                            <Modal
                              isOpen={classtoggle}
                              toggle={() => {
                                handleSTUDENTtoggle()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Modal Heading
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleSTUDENTtoggle()
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  handleStudendpost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    {RoleAllowed === "Student" ? (
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom02">
                                            classID
                                          </Label>
                                          <AvField
                                            name="classID"
                                            type="select"
                                            value={getclassdata?._id}
                                            //   editDefaultValue={update.classID}
                                            //   onChange={e =>
                                            //     setpricepost({
                                            //       ...pricepost,
                                            //       customerID: e.target.value,
                                            //     })
                                            //   }
                                            disabled
                                            // errorMessage="Enter customer Name"
                                            className="form-control"
                                            // validate={{
                                            //   required: { value: true },
                                            // }}
                                            id="validationCustom02"
                                          >
                                            {/* <option value="">
                                               {" "}
                                               select className
                                             </option> */}

                                            <option>
                                              {getclassdata?.ClassName}
                                            </option>
                                          </AvField>
                                        </FormGroup>
                                      </Col>
                                    ) : (
                                      // <Col md="6">
                                      //   <FormGroup className="mb-3">
                                      //     <Label htmlFor="validationCustom02">
                                      //       class Name
                                      //     </Label>
                                      //     <AvField
                                      //       name="classID"
                                      //       value={getclassdata}
                                      //       disabled
                                      //       placeholder="StudentID"
                                      //       type="text"
                                      //       // errorMessage="Enter StudentID"
                                      //       className="form-control"
                                      //       // validate={{
                                      //       //   required: { value: true },
                                      //       // }}
                                      //       id="validationCustom02"
                                      //     />
                                      //   </FormGroup>
                                      // </Col>
                                      // <Col md="6">
                                      //   <FormGroup className="mb-3">
                                      //     <Label htmlFor="validationCustom02">
                                      //       classID
                                      //     </Label>
                                      //     <AvField
                                      //       name="classID"
                                      //       type="select"
                                      //       value={getclassdata?._id}
                                      //       //   editDefaultValue={update.classID}
                                      //       //   onChange={e =>
                                      //       //     setpricepost({
                                      //       //       ...pricepost,
                                      //       //       customerID: e.target.value,
                                      //       //     })
                                      //       //   }
                                      //       disabled
                                      //       // errorMessage="Enter customer Name"
                                      //       className="form-control"
                                      //       // validate={{
                                      //       //   required: { value: true },
                                      //       // }}
                                      //       id="validationCustom02"
                                      //     >
                                      //       {/* <option value="">
                                      //         {" "}
                                      //         select className
                                      //       </option> */}

                                      //       <option>
                                      //         {getclassdata?.ClassName}
                                      //       </option>
                                      //     </AvField>
                                      //   </FormGroup>
                                      // </Col>
                                      // <Col md="6">
                                      //   <FormGroup className="mb-3">
                                      //     <Label htmlFor="validationCustom02">
                                      //       class Name
                                      //     </Label>
                                      //     <AvField
                                      //       name="classID"
                                      //       value={getclassdata}
                                      //       disabled
                                      //       placeholder="StudentID"
                                      //       type="text"
                                      //       // errorMessage="Enter StudentID"
                                      //       className="form-control"
                                      //       // validate={{
                                      //       //   required: { value: true },
                                      //       // }}
                                      //       id="validationCustom02"
                                      //     />
                                      //   </FormGroup>
                                      // </Col>
                                      <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom02">
                                            classID
                                          </Label>
                                          <AvField
                                            name="classID"
                                            type="select"
                                            value={update?.classID?._id}
                                            // defua
                                            //   editDefaultValue={update.classID}
                                            //   onChange={e =>
                                            //     setpricepost({
                                            //       ...pricepost,
                                            //       customerID: e.target.value,
                                            //     })
                                            //   }
                                            errorMessage="Enter customer Name"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id="validationCustom02"
                                          >
                                            <option value="">
                                              {" "}
                                              select className
                                            </option>

                                            {getclasses.map(classes => {
                                              return (
                                                <option value={classes._id}>
                                                  {" "}
                                                  {classes.ClassName}
                                                </option>
                                              )
                                            })}
                                          </AvField>
                                        </FormGroup>
                                      </Col>
                                    )}

                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Student ID
                                        </Label>
                                        <AvField
                                          name="StudentID"
                                          value={update.StudentID}
                                          placeholder="StudentID"
                                          type="number"
                                          errorMessage="Enter StudentID"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          StudentName
                                        </Label>
                                        <AvField
                                          name="StudentName"
                                          value={update.StudentName}
                                          placeholder="StudentName"
                                          type="text"
                                          errorMessage="Enter StudentName"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleSTUDENTtoggle()
                                    }}
                                    className="btn  waves-effect"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    disabled={
                                      sid !== "" ? updateloading : isLoading
                                    }
                                    className="btn  waves-effect waves-light"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {ClassALERT ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Student
