import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import servicesIcon1 from "../../assets/images/services-icon/01.png"
import servicesIcon2 from "../../assets/images/services-icon/02.png"
import servicesIcon3 from "../../assets/images/services-icon/03.png"
import servicesIcon4 from "../../assets/images/services-icon/04.png"
import user5 from "../../assets/images/users/user-5.jpg"
import user6 from "../../assets/images/users/user-6.jpg"
import smimg1 from "../../assets/images/small/img-1.jpg"
import smimg2 from "../../assets/images/small/img-2.jpg"
import { Link } from "react-router-dom"

// import {
//   Col,
//   Container,
//   Row,
//   Dropdown,
//   DropdownToggle,
//   DropdownMenu,
//   DropdownItem,
// } from "reactstrap"
import {
  Container,
  Row,
  Col,
  Button,
  Card,
  CardBody,
  Input,
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
} from "reactstrap"
import axios from "axios"
import { useEffect } from "react"
const Dashboard = () => {
  const [menu, setMenu] = useState(false)
  const [getdata, setgetdata] = useState([])
  const [marksdata, setmarksdata] = useState([])
  const toggle = () => {
    setMenu(!menu)
  }
  useEffect(() => {
    async function onload() {
      try {
        // const token=loc

        const token = localStorage.getItem("token")
        const headers = {
          headers: {
            "Content-type": "application/json",
            Authorization: `Bearer ${token}`,
          },
        }
        const epiEndPoint =
          "https://macalinkaal-api.vercel.app/Summy"
        const { data } = await axios.get(epiEndPoint, headers)
        setmarksdata(data.marksdata)
        setgetdata(data)
        // console.log("data", data)
      } catch (error) {
        Swal.fire("error!", error.message, "error")

        // console.log(error.message)
      }
    }
    onload()
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>
            Dashboard | Veltrix - Responsive Bootstrap 5 Admin Dashboard
          </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Dashboard</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Veltrix Dashboard
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block"></div>
              </Col>
              <Row>
                <Col xl={4} md={6}>
                  <Card
                    className="mini-stat  text-white"
                    style={{ backgroundColor: "#E43825" }}
                  >
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon1} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          classnumber
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {getdata.classnumber}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-success">
                          <p className="mb-0">+ 12%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>
                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card
                    className="mini-stat  text-white"
                    style={{ backgroundColor: "#E43825" }}
                  >
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon2} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Total Class-monitors
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {getdata?.munitarnumber}
                          <i className="mdi mdi-arrow-down text-danger ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-danger">
                          <p className="mb-0">- 28%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
                <Col xl={4} md={6}>
                  <Card
                    className="mini-stat  text-white"
                    style={{ backgroundColor: "#E43825" }}
                  >
                    <CardBody>
                      <div className="mb-4">
                        <div className="float-start mini-stat-img me-4">
                          <img src={servicesIcon3} alt="" />
                        </div>
                        <h5 className="font-size-16 text-uppercase mt-0 text-white-50">
                          Students
                        </h5>
                        <h4 className="fw-medium font-size-24">
                          {getdata?.studentnumber}
                          <i className="mdi mdi-arrow-up text-success ms-2"></i>
                        </h4>
                        {/* <div className="mini-stat-label bg-info">
                          <p className="mb-0"> 00%</p>
                        </div> */}
                      </div>
                      <div className="pt-2">
                        <div className="float-end">
                          <Link to="#" className="text-white-50">
                            <i className="mdi mdi-arrow-right h5"></i>
                          </Link>
                        </div>

                        <p className="text-white-50 mb-0 mt-1">
                          Since last month
                        </p>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
              <Row>
                <Col xl={12}>
                  <Card>
                    <CardBody>
                      <h4 className="card-title mb-4">Top five Students</h4>
                      <div className="table-responsive">
                        <table className="table table-hover table-centered table-nowrap mb-0">
                          <thead>
                            <tr>
                              {/* <th scope="col">(#) Id</th> */}
                              <th scope="col">StudentID</th>
                              <th scope="col">StudentName</th>
                              <th scope="col">TotalMarks</th>
                            </tr>
                          </thead>
                          <tbody>
                            {marksdata?.map(data => {
                              return (
                                <tr>
                                  {/* <th scope="row">#14256</th> */}

                                  <td>{data?.StudentID}</td>
                                  <td>{data?.StudentName}</td>
                                  <td>{data?.TotalMarks}</td>

                                  {/* <td>15/1/2018</td> */}
                                </tr>
                              )
                            })}
                          </tbody>
                        </table>
                      </div>
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </Row>
          </div>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Dashboard
