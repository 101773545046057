import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query"
import {
  AddNewData,
  DeleteAllData,
  GetAllData,
  UpdateClientData,
} from "CrudOperetion/CrudOpereation"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
// import { toast } from "react-toastify"
// import "toastr"

// get query string
export const GetQuery = (Endpoint, querykey) => {
  return useQuery({
    queryKey: [querykey],
    queryFn: () => GetAllData(Endpoint),
  })
}
export const GetByQuery = (Endpoint, id, querykey) => {
  return useQuery({
    queryKey: [querykey],
    queryFn: () => GetAllData(Endpoint, id),
  })
}
export const AddQuery = (endpiont, querykey) => {
  const queryclient = useQueryClient()
  return useMutation({
    mutationFn: data => AddNewData(endpiont, data),
    onSuccess: () => {
      queryclient.invalidateQueries({ queryKey: [querykey] })
    },
    onError: () => {
      Swal.fire("?", "xogta lama xareen", "error")

      // alert("xogta lama xareen")
      //   toast.error("xogta lama xareen")
    },
  })
}
export const UpdateQuery = (endpoint, id, querykey) => {
  const queryclient = useQueryClient()

  return useMutation({
    mutationFn: data => UpdateClientData(endpoint, id, data),
    onSuccess: () => {
      queryclient.invalidateQueries({ queryKey: [querykey] })
    },
    onError: () => {
      Swal.fire("?", "xogta lama xareen", "error")

      // toast.error('Failed to update client');
      // alert("Failed to update client")
    },
  })
}
export const DeleteQuery = (endpoint, querykey) => {
  const queryclient = useQueryClient()

  return useMutation({
    mutationFn: id => DeleteAllData(endpoint, id),
    onSuccess: () => {
      queryclient.invalidateQueries({ queryKey: [querykey] })
    },
    onError: () => {
      // toast.error('xogta lama delete gareen');
      alert("xogta lama delete gareen")
    },
  })
}
