import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { Link } from "react-router-dom"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import { useReactToPrint } from "react-to-print"
import {
  Table,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
} from "reactstrap"
import axios from "axios"
import { useEffect } from "react"
import { AddQuery, GetQuery } from "QueryApi/Reactapi"
import { useRef } from "react"
import { TailSpin } from "react-loader-spinner"
import ReactHTMLTableToExcel from 'react-html-table-to-excel';
const ReportMarks = () => {
  const [getclasses, setgetclasses] = useState([])
  const [markesget, setmarkesget] = useState([])
  // get class
  const { data } = GetQuery("/class", "class")

  useEffect(() => {
    async function onlood() {
      // const { data } = await axios.get("http://localhost:2050/class")
      // console.log(data)
      if (data) {
        setgetclasses(data)
      }
    }
    onlood()
  }, [data])
  // console.log("ta", getclasses)

  const { mutateAsync: Addnewmuate, isLoading } = AddQuery("/report", "report")
  const handleClasspost = async (event, value) => {
    Addnewmuate(value).then(res => {
      setmarkesget(res?.data)

      // console.log(res?.data)
    })
    // const { data } = await axios.post("http://localhost:2050/report", value)
    // setmarkesget(data)
    // // Addnewmuate(value).then(res => {
    // //   console.log(res)
    // // })
    // console.log(data)
  }
  //print
  const convertToPDF = useRef()
  const generateTo = useReactToPrint({
    content: () => convertToPDF.current,
    documentTitle: "order-report",
    // documentTitle:'userData',
    // onAfterPrint: () => alert("successFuly Saved As PDF"),
  })
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Report Marks | </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="MacalinKaal"
              title="Table"
              breadcrumbItem="Class Report"
            />
            {/* <Row className="align-items-center">
              <Col md={8}>
                <h6 className="page-title">Report marks</h6>
                <ol className="breadcrumb m-0">
                  <li className="breadcrumb-item active">
                    Welcome to Veltrix report marks
                  </li>
                </ol>
              </Col>

              <Col md="4">
                <div className="float-end d-none d-md-block"></div>
              </Col>
            </Row> */}
          </div>

          <Row>
            <Col md="8">
              <AvForm
                className="needs-validation"
                onValidSubmit={(e, v) => {
                  handleClasspost(e, v)
                }}
              >
                <div className="row mb-5">
                  <div className="col-8">
                    <FormGroup className="">
                      <Label htmlFor="validationCustom02">classID</Label>
                      <AvField
                        name="classid"
                        type="select"
                        //   value={update.classID}
                        //   editDefaultValue={update.classID}
                        //   onChange={e =>
                        //     setpricepost({
                        //       ...pricepost,
                        //       customerID: e.target.value,
                        //     })
                        //   }
                        errorMessage="Enter customer Name"
                        className="form-control"
                        validate={{
                          required: { value: true },
                        }}
                        id="validationCustom02"
                      >
                        <option value=""> select className</option>
                        {getclasses.map(classes => {
                          return (
                            <option value={classes._id}>
                              {" "}
                              {classes.ClassName}
                            </option>
                          )
                        })}
                      </AvField>
                    </FormGroup>
                  </div>
                  <div className="col-2" style={{ marginTop: "29px" }}>
                    <div>
                      <button
                        type="submit"
                        className="btn  waves-effect waves-light"
                        style={{
                          backgroundColor: "#E43825",
                          color: "white",
                        }}
                      >
                        Search
                      </button>
                    </div>
                  </div>
                  {markesget?.length > 0 && (
                    <div className="col-2" style={{ marginTop: "29px" }}>
                     <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn waves-effect  bg-success text-white"
                    table="table-to-xls"
                    filename="table_data"
                    sheet="Sheet1"
                    buttonText="Get as Excel"
                  />
                    </div>
                  )}
                </div>
              </AvForm>
            </Col>
          </Row>
          {isLoading ? (
                   <div
                  style={{
                   display:'flex',
                   alignItems:'center',
                   justifyContent:'center',
                   marginTop:"40px"
                  }}
                
                >
             <div style={{color:"#E43825",textAlign:"center"}}>

            {/* it might take a while, so please wait */}
            
             <TailSpin
               height="40"
               width="40"
               color="#E43825"
               ariaLabel="tail-spin-loading"
               radius="1"
               wrapperStyle={{}}
               wrapperClass=""
               visible={true}
             />
              </div>
             </div>
                ) : (
          <div className="table-responsive">
            {markesget == "" ? (
              <p
                style={{
                  // fontSize: "20px",
                  // fontFamily: "monospace",
                  // fontStyle: "oblique",
                  textAlign: "center",
                  // color: "#13152c",
                }}
              >
                pls choose class name
              </p>
            ) : (
              <div ref={convertToPDF}>
                
                  <Table id="table-to-xls" className="table mb-0">
                    <thead className="table-light">
                      <tr>
                        {/* <th>ClassName</th> */}
                        <th>StudentID</th>
                        <th>StudentName</th>
                        <th>Activity </th>
                        <th>Exam-Paper </th>
                      </tr>
                    </thead>
                    {markesget?.map(data => {
                      return (
                        <>
                          <tbody>
                            <tr>
                              {/* <th scope="row">{data.classID}</th> */}
                              <td>{data.StudentID}</td>
                              <td>{data.StudentName}</td>
                              <td>{data.activityMarks}</td>
                              <td>{data.examPaperMarks}</td>
                            </tr>
                          </tbody>
                        </>
                      )
                    })}

                    {/* <tbody>
                <tr>
                  <th scope="row">1</th>
                  <td>Mark</td>
                  <td>Otto</td>
                  <td>@mdo</td>
                </tr>
                <tr>
                  <th scope="row">2</th>
                  <td>Jacob</td>
                  <td>Thornton</td>
                  <td>@fat</td>
                </tr>
                <tr>
                  <th scope="row">3</th>
                  <td>Larry</td>
                  <td>the Bird</td>
                  <td>@twitter</td>
                </tr>
              </tbody> */}
                  </Table>
                {/* )} */}
              </div>
            )}
          </div>
                )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ReportMarks
