import React, { useState } from "react";
import MetaTags from "react-meta-tags";
import Select from "react-select";
import { GetQuery, AddQuery } from "QueryApi/Reactapi";
import { TailSpin } from "react-loader-spinner";
import Breadcrumbs from "../../components/Common/Breadcrumb";
import {
  Table,
  Container,
  Label
} from "reactstrap";
import ReactHTMLTableToExcel from 'react-html-table-to-excel';

const PersonalReportMarks = () => {
  const [markesget, setmarkesget] = useState([]);
  const [selectdata, setselectdata] = useState({
    ActivityID: "",
    classID: ""
  });

  const { data: activities } = GetQuery("/Activity", "Activity");
  const { data: classId } = GetQuery("/class", "class");

  const optionGroup = activities ? activities.map(data => ({ label: data?.Activity, value: data?._id })) : [];
  const optionGroup2 = classId ? classId.map(data => ({ label: data?.ClassName, value: data?._id })) : [];

  const { mutateAsync: Addnewmuate, isLoading } = AddQuery("/report/byActivity", "byActivity");

  const handleSubmit = async e => {
    e.preventDefault();
    const res = await Addnewmuate(selectdata);
    setmarkesget(res?.data);
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Report Marks | </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="MacalinKaal"
              title="Table"
              breadcrumbItem="Student"
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-4">
                <Label> Select Class</Label>
                <Select
                  name="classID"
                  value={selectdata.value}
                  onChange={value => {
                    setselectdata({ ...selectdata, classID: value.value })
                  }}
                  options={optionGroup2}
                  classNamePrefix="select2-selection"
                />
                
              </div>{" "}
              <div className="col-4">
              <Label> Select Activity</Label>
                <Select
                  name="ActivityID"
                  value={selectdata.value}
                  onChange={value => {
                    setselectdata({ ...selectdata, ActivityID: value.value })
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
                </div>
              <div className="col-4" style={{ marginTop: "29px" }}>
                <button
                  type="submit"
                  className="btn  waves-effect waves-light mb-3"
                  style={{
                    backgroundColor: "#E43825",
                    color: "white",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </form>

          {isLoading ? (
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginTop: "40px" }}>
              <TailSpin height="40" width="40" color="#E43825" ariaLabel="tail-spin-loading" radius="1" visible={true} />
            </div>
          ) : (
            markesget?.length > 0 && (
              <div className="table-responsive mt-5">
                <div style={{ padding: '20px' }}>
                  <p>Total Students: {markesget.length}</p>
                  <Table id="table-to-xls" className="table mb-0">
                    <thead className="table-light">
                      <tr>
                        <th>Student Name</th>
                        <th>Activity</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody>
                      {markesget.map((data, index) => (
                        <tr key={index}>
                          <td>{data.StudentID?.StudentName}</td>
                          <td>{data.ActivityID?.Activity}</td>
                          <td>Done</td>
                        </tr>
                      ))}
                    </tbody>
                  </Table>
                  <ReactHTMLTableToExcel
                    id="test-table-xls-button"
                    className="btn waves-effect mx-2 my-3 bg-success text-white"
                    table="table-to-xls"
                    filename="table_data"
                    sheet="Sheet1"
                    buttonText="Download as Excel"
                  />
                </div>
              </div>
            )
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default PersonalReportMarks;
