import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import axios from "axios"
// import "toastr"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import { useEffect } from "react"
import { AddQuery, DeleteQuery, GetQuery, UpdateQuery } from "QueryApi/Reactapi"
import SweetAlert from "react-bootstrap-sweetalert"
import moment from "moment"
// import { reset } from "redux-form"
function ClassComp() {
  const [classtoggle, setclasstoggle] = useState(false)
  const [update, setupdate] = useState({})
  const [ClassALERT, setClassALERT] = useState(false)
  const [Cid, setCid] = useState("")
  const [deleteID, setdeleteID] = useState("")
  const handleclasstoggle = () => {
    setclasstoggle(!classtoggle)
  }
  const handlenewIcon = () => {
    handleclasstoggle()
    setCid("")
    setupdate("")
  }
  // get data class
  const { data: classdata } = GetQuery("/form", "form")
  // console.log(classdata)
  // add new class
  const { mutateAsync, isLoading } = AddQuery("/form", "form")
  //update query
  const { mutateAsync: updatemuate, isLoading: updateloading } = UpdateQuery(
    "/form",
    Cid,
    "form"
  )

  // post data
  const handleClasspost = (event, value) => {
    try {
      if (Cid !== "") {
        updatemuate(value).then(res => {
          let message = res?.data?.message
          Swal.fire("success!", message, "success")
          handleclasstoggle()
          // reset()
        })
      } else {
        mutateAsync(value).then(res => {
          let message = res?.data?.message
          Swal.fire("success!", message, "success")

          // alert("class created successfully")
          handleclasstoggle()
        })
      }
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log(value)
  }
  //update class
  const updatclassData = data => {
    setupdate(data)
    setCid(data._id)
    handleclasstoggle()
  }
  // delete class
  const { mutateAsync: deletemuate } = DeleteQuery("/form", "form")
  const handledelete = data => {
    setdeleteID(data._id)
    setClassALERT(true)
  }
  const deleteallprice = () => {
    try {
      deletemuate(deleteID).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        setClassALERT(false)
      })
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log("first delete")
  }
  const data = {
    columns: [
      {
        label: "name",
        field: "name",
        sort: "asc",
        width: 150,
      },
      {
        label: "phone",
        field: "phone",
        sort: "asc",
        width: 150,
      },
      {
        label: "university",
        field: "university",
        sort: "asc",
        width: 150,
      },
      {
        label: "course",
        field: "course",
        sort: "asc",
        width: 150,
      },
      {
        label: "Date",
        field: "createdAt",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: classdata?.map(data => {
      data.createdAt=moment(data.createdAt).format('YYYY-MM-DD')
      data.action = (
        <div>
          {/* <button
            className="btn   "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => updatclassData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button> */}
          <button
            className="btn   mx-3 "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => handledelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button>
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Form Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="Form "
          />
{/* <div  style={{
                                backgroundColor: "#E43825",
                                color: "white",
                                display:"flex",
                                justifyContent:"end"
                              }}>
                          <div className="my-2 mx-3">
                            <button
                              type="button"
                              onClick={() => {
                                handlenewIcon()
                              }}
                              className="btn btn-outline-light  waves-effect waves-light"

                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              Add new class
                            </button>
                           
                    </div>
                  </div> */}
          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">CLASS LIST </CardTitle> */}
                  
                  <Modal
                              isOpen={classtoggle}
                              toggle={() => {
                                handleclasstoggle()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Modal Heading
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleclasstoggle()
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  handleClasspost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    <Col md="12">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          CLASSNAME
                                        </Label>
                                        <AvField
                                          name="ClassName"
                                          value={update.ClassName}
                                          placeholder="ClassName"
                                          type="text"
                                          errorMessage="Enter ClassName"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleclasstoggle()
                                    }}
                                    className="btn  waves-effect"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    disabled={
                                      Cid !== "" ? updateloading : isLoading
                                    }
                                    className="btn  waves-effect waves-light"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {ClassALERT ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default ClassComp
