import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import axios from "axios"
// import "toastr"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import { useEffect } from "react"
import { AddQuery, DeleteQuery, GetQuery, UpdateQuery } from "QueryApi/Reactapi"
import SweetAlert from "react-bootstrap-sweetalert"
// import { reset } from "redux-form"
function SubMarks() {
  const [classtoggle, setclasstoggle] = useState(false)
  const [update, setupdate] = useState({})
  const [ClassALERT, setClassALERT] = useState(false)
  const [Cid, setCid] = useState("")
  const [deleteID, setdeleteID] = useState("")
  const handleclasstoggle = () => {
    setclasstoggle(!classtoggle)
  }
  const handlenewIcon = () => {
    handleclasstoggle()
    // setCid("")
    // setupdate("")
  }
  // get data class
  const { data: submarkdata } = GetQuery("/submark", "submark")
  // console.log(classdata)
  // add new class
  const { mutateAsync, isLoading } = AddQuery("/submark", "submark")
  //update query
  //   const { mutateAsync: updatemuate, isLoading: updateloading } = UpdateQuery(
  //     "/class",
  //     Cid,
  //     "class"
  //   )

  // post data
  const handleClasspost = (event, value) => {
    try {
      //   if (Cid !== "") {
      //     updatemuate(value).then(res => {
      //       let message = res?.data?.message
      //       Swal.fire("success!", message, "success")
      //       handleclasstoggle()
      //       // reset()
      //     })
      //   } else {
      //   return console.log(value)
      mutateAsync(value).then(res => {
        if (res?.data?.status == true) {
          let message = res?.data?.message
          Swal.fire("success!", message, "success")

          // alert("class created successfully")
          handleclasstoggle()
        } else if (res?.data?.status == 404) {
          //   let message = res?.data?.message
          Swal.fire("error!", res?.message, "error")
        }
      })
      //   }
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log(value)
  }
  //update class
  //   const updatclassData = data => {
  //     setupdate(data)
  //     setCid(data._id)
  //     handleclasstoggle()
  //   }
  // delete class
  //   const { mutateAsync: deletemuate } = DeleteQuery("/class", "class")
  //   const handledelete = data => {
  //     setdeleteID(data._id)
  //     setClassALERT(true)
  //   }
  //   const deleteallprice = () => {
  //     try {
  //       deletemuate(deleteID).then(res => {
  //         let message = res?.data?.message
  //         Swal.fire("success!", message, "success")
  //         setClassALERT(false)
  //       })
  //     } catch (error) {
  //       Swal.fire("?", error.message, "error")
  //     }

  //     // console.log("first delete")
  //   }
  const data = {
    columns: [
      {
        label: "Studentid",
        field: "Studentid",
        sort: "asc",
        width: 150,
      },
      {
        label: "className",
        field: "className",
        sort: "asc",
        width: 150,
      },
      {
        label: "SubMarks",
        field: "SubMarks",
        sort: "asc",
        width: 150,
      },
      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: submarkdata?.map(data => {
      data.className = data.classID?.ClassName

      data.action = (
        <div>
          {/* <button
            className="btn   "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => updatclassData(data)}
          >
            {" "}
            <i className="ion ion-md-create font-size-20 m-auto"></i>
          </button> */}
          {/* <button
            className="btn   mx-3 "
            style={{
              // backgroundColor: "#E43825",
              color: "#E43825",
            }}
            onClick={() => handledelete(data)}
          >
            {" "}
            <i className="ion ion-md-trash font-size-20 m-auto"></i>
          </button> */}
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Class Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="submark "
          />

          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">CLASS LIST </CardTitle> */}
                  <div className="row">
                    <div className="col-10"></div>
                    <div className="col-2">
                      <Row>
                        <Col sm={6} md={4} xl={3}>
                          <div className="my-3 text-center">
                            <button
                              type="button"
                              onClick={() => {
                                handlenewIcon()
                              }}
                              className="btn  waves-effect waves-light"
                              style={{
                                backgroundColor: "#E43825",
                                color: "white",
                              }}
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              <i className="ion ion-md-add"> </i>
                            </button>
                            <Modal
                              isOpen={classtoggle}
                              toggle={() => {
                                handleclasstoggle()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Modal Heading
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleclasstoggle()
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  handleClasspost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Studentid
                                        </Label>
                                        <AvField
                                          name="Studentid"
                                          //   value={update.ClassName}
                                          placeholder="Studentid"
                                          type="number"
                                          errorMessage="Enter Studentid"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          SubMarks
                                        </Label>
                                        <AvField
                                          name="SubMarks"
                                          //   value={update.ClassName}
                                          placeholder="SubMarks"
                                          type="number"
                                          errorMessage="Enter SubMarks"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleclasstoggle()
                                    }}
                                    className="btn  waves-effect"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    disabled={isLoading}
                                    className="btn  waves-effect waves-light"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {/* {ClassALERT ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null} */}
        </div>
      </div>
    </React.Fragment>
  )
}

export default SubMarks
