import PropTypes from "prop-types"
import MetaTags from "react-meta-tags"
import React, { useEffect } from "react"

import { Row, Col, CardBody, Card, Alert, Container } from "reactstrap"

// Redux
// import { connect } from "react-redux"
import { withRouter, Link } from "react-router-dom"

// availity-reactstrap-validation
import { AvForm, AvField } from "availity-reactstrap-validation"


// actions

// import images
import logoSm from "../../assets/images/logopng-01.png"
import axios from "axios"
import Swal, { swal } from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"
import { useUserContext } from "ContextApi/Context"
import Logout from "./Logout"
import { ThreeCircles } from "react-loader-spinner"

import { AddQuery } from "QueryApi/Reactapi"

const Login = () => {
  const { setemail } = useUserContext()
  useEffect(() => {
    function load() {
      // Logout()
      localStorage.removeItem("token")
    }
    load()
  }, [])
  let history = useHistory()
  const { mutateAsync, isLoading } = AddQuery("/users/Login", "login")

  const handleValidSubmit = async (event, value) => {
    try {
      mutateAsync(value).then(res => {
        if (res?.data?.status == true) {
          setemail(value.email)
          localStorage.setItem("token", res?.data?.token)

          // Swal.fire("success!", res?.data?.message, "success")
        
          history.push("/dashboard")
        } else {
          Swal.fire("error!", res?.data?.message, "error")
        }
      })
    } catch (error) {
      Swal.fire("error!", error.message, "error")
    }

    // console.log(value)
    //   const { data } = await axios.post(
    //     "https://macalinkaal-production.up.railway.app/users/Login",
    //     value
    //   )
    // if (data.status == true) {
    //   setemail(value.email)
    //   localStorage.setItem("token", data.token)

    //   Swal.fire("success!", data.message, "success")
    //   history.push("/dashboard")
    // } else {
    //   Swal.fire("error!", data.message, "error")
    // }
  }
  // handleValidSubmit
  // const handleValidSubmit = (event, values) => {
  //   props.loginUser(values, props.history)
  // }

  return (
    <React.Fragment>
      <MetaTags>
        <title>Login | Veltrix - Responsive Bootstrap 5 Admin Dashboard</title>
      </MetaTags>
      <div className="home-btn d-none d-sm-block">
        <Link to="/" className="text-dark">
          <i className="fas fa-home h2" />
        </Link>
      </div>
   
      <div className="account-pages my-5 pt-sm-5">
        <Container>
          <Row className="justify-content-center">
            <Col md={8} lg={6} xl={4}>
              <Card className="overflow-hidden">
                <div className="" style={{ backgroundColor: "#13152c" }}>
                  <div className="text-primary text-center p-4">
                    <h5 className="text-white font-size-20">
                      Welcome MACALIN KAAL App !
                    </h5>
                    <p className="text-white-50">
                      Sign in to continue to Macalin Kaal.
                    </p>
                    <Link to="/" className="logo logo-admin">
                      <img src={logoSm} height="28" alt="logo" />
                    </Link>
                  </div>
                </div>

                <CardBody className="p-4">
                  <div className="p-3">
                    <AvForm
                      className="form-horizontal mt-4"
                      onValidSubmit={(e, v) => {
                        handleValidSubmit(e, v)
                      }}
                    >
                      <div className="mb-3">
                        <AvField
                          name="email"
                          label="Email"
                          // value="admin@themesbrand.com"
                          className="form-control"
                          placeholder="Enter email"
                          type="email"
                          required
                        />
                      </div>

                      <div className="mb-3">
                        <AvField
                          name="password"
                          label="Password"
                          // value="123456"
                          type="password"
                          required
                          placeholder="Enter Password"
                        />
                      </div>

                      <Row className="mb-3">
                        <Col sm={6}>
                          <div className="form-check">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="customControlInline"
                            />
                            <label
                              className="form-check-label"
                              htmlFor="customControlInline"
                            >
                              Remember me
                            </label>
                          </div>
                        </Col>
                        <Col sm={6} className="text-end">
                          <button
                            className="btn  w-md waves-effect waves-light"
                            style={{
                              backgroundColor: "#13152c",
                              color: "white",
                            }}
                            type="submit"
                          >
                            {isLoading ? (
                              <ThreeCircles
                                height="20"
                                width="100"
                                color="white"
                                wrapperStyle={{}}
                                wrapperClass=""
                                visible={true}
                                ariaLabel="three-circles-rotating"
                                outerCircleColor=""
                                innerCircleColor=""
                                middleCircleColor=""
                              />
                            ) : (
                              "Login"
                            )}
                          </button>
                        </Col>
                      </Row>
                      {/* <Row className="mt-2 mb-0 row">
                        <div className="col-12 mt-4">
                          <Link to="/forgot-password">
                            <i className="mdi mdi-lock"></i> Forgot your
                                password?
                              </Link>
                        </div>
                      </Row> */}
                    </AvForm>
                  </div>
                </CardBody>
              </Card>
              <div className="mt-5 text-center">
                <p>
                  © {new Date().getFullYear()} MADE. with
                  <i className="mdi mdi-heart text-danger" /> by ENG AHMED ALI &
                  ENG HASSAN OMAR
                </p>
              </div>
            </Col>
          </Row>
        </Container>
      </div>
    </React.Fragment>
  )
}

export default Login
