import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"

import {
  Table,
  Col,
  Container,
  Row,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
  FormGroup,
  Label,
} from "reactstrap"
import Select from "react-select"

import axios from "axios"
import { useEffect } from "react"
import { AddQuery, GetQuery } from "QueryApi/Reactapi"
import { MagnifyingGlass, RotatingLines, TailSpin } from "react-loader-spinner"

import Breadcrumbs from "../../components/Common/Breadcrumb"

const personalReportMarks = () => {
  const [getstudent, setgetstudent] = useState([])
  const [markesget, setmarkesget] = useState([])
  const [selectdata, setselectdata] = useState({
    StudentId: "",
  })
  // get student
  const { data } = GetQuery("/student", "student")
  // console.log(data)
  // setgetstudent(data)

  // useEffect(() => {
  //   async function onlood() {
  //     if (data) {
  //       setgetstudent(data)
  //     }
  //   }
  //   onlood()
  // }, [])
  const optionGroup = [
    {
      // label: "Picnic",
      options: data?.map(data => {
        return { label: data?.StudentID, value: data?._id }
      }),
    },
  ]

  // console.log(getstudent)
  const { mutateAsync: Addnewmuate,isLoading } = AddQuery("/personal", "personal")
  // const handleClasspost = async (event, value) => {
  //   const { data } = await axios.post("http://localhost:2050/personal", value)
  //   setmarkesget(data)
  //   // Addnewmuate(value).then(res => {
  //   //   console.log(res)
  //   // })
  //   console.log("data", data)
  // }
  // const handleSelectGroup = selectedOption => {
  //   // console.log(selectedOption)
  //   setselectdata(selectedOption)
  // }
  const handleSubmit = async e => {
    e.preventDefault()
    Addnewmuate(selectdata).then(res => {
      setmarkesget(res?.data)
      // console.log(res?.data)
    })
    // const { data } = await axios.post(
    //   "http://localhost:2050/personal",
    //   selectdata
    // )
    // setmarkesget(data)
    // console.log(data)
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title> Report Marks | </title>
        </MetaTags>
        <Container fluid>
          <div className="page-title-box">
            <Breadcrumbs
              maintitle="MacalinKaal"
              title="Table"
              breadcrumbItem="Student"
            />
          </div>

          <form onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-8">
                <Label> Select StudentID</Label>
                <Select
                  name="StudentId"
                  value={selectdata.value}
                  onChange={value => {
                    setselectdata({ ...selectdata, StudentId: value.value })
                  }}
                  options={optionGroup}
                  classNamePrefix="select2-selection"
                />
              </div>{" "}
              <div className="col-4" style={{ marginTop: "29px" }}>
                <button
                  type="submit"
                  className="btn  waves-effect waves-light mb-3"
                  style={{
                    backgroundColor: "#E43825",
                    color: "white",
                  }}
                >
                  Search
                </button>
              </div>
            </div>
          </form>
{isLoading ? <div
     style={{
      display:'flex',
      alignItems:'center',
      justifyContent:'center',
      marginTop:"40px"
     }}
   
   >

<TailSpin
  height="40"
  width="40"
  color="#E43825"
  ariaLabel="tail-spin-loading"
  radius="1"
  wrapperStyle={{}}
  wrapperClass=""
  visible={true}
/></div>:
 <div className="table-responsive mt-5">
 {markesget == "" ? (
   <p
     style={{
      //  fontSize: "20px",
      //  fontFamily: "monospace",
      //  fontStyle: "oblique",
       textAlign: "center",
       marginTop:"20px"
      //  color: "#13152c",
     }}
   
   >
     
     pls choose Student ID
   </p>
 ) : (
   <Table className="table mb-0">
     <thead className="table-light">
       <tr>
         <th>StudentName</th>
         <th>Activity</th>
         <th>Marks</th>
       </tr>
     </thead>
     {markesget?.map(data => {
       return (
         <>
           <tbody>
             <tr>
               <td>{data.StudentID?.StudentName}</td>
               <td>{data.ActivityID?.Activity}</td>
               <td>{data.Marks}</td>
             </tr>
           </tbody>
         </>
       )
     })}
   </Table>
 )}
</div>
}
         
        </Container>
      </div>
    </React.Fragment>
  )
}

export default personalReportMarks
