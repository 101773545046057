import PropTypes from "prop-types"
import React, { useEffect } from "react"
import { connect } from "react-redux"
import { withRouter } from "react-router-dom"

import { logoutUser } from "../../store/actions"
import { useHistory } from "react-router-dom/cjs/react-router-dom.min"

const Logout = () => {
  // const nagigate=useNavigate()
  let history = useHistory()

  localStorage.removeItem("token")
  history.push("/login")
  // nagigate('/login')
}
export default Logout

// Logout.propTypes = {
//   history: PropTypes.object,
//   logoutUser: PropTypes.func,
// }

// export default withRouter(connect(null, { logoutUser })(Logout))
