import axios from "axios"
// import cookie from 'js-cookie';
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"

const api = axios.create({
  // baseURL: "http://localhost:3008",
  baseURL: "https://macalinkaal-api.vercel.app",
})
api.interceptors.request.use(
  config => {
    const token = localStorage.getItem("token")

    const auth = token ? `Bearer ${token}` : ""
    config.headers.Authorization = auth
    return config
  },
  error => Promise.reject(error)
)
api.interceptors.response.use(
  response => response,
  error => {
    console.log(error)
    try {
      Swal.fire("?", error.response?.data.message, "error")

      if (error.response.status === 403) {
        localStorage.removeItem("token")
        window.location.href = "/"
      }
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }
    // toast.error(error.response?.data.message)
  }
)
export { api }
