import React, { useState } from "react"
import MetaTags from "react-meta-tags"
import { AvForm, AvField } from "availity-reactstrap-validation"
import { MDBDataTable } from "mdbreact"
import {
  Col,
  Container,
  Row,
  Card,
  CardBody,
  Modal,
  FormGroup,
  Label,
} from "reactstrap"
import Breadcrumbs from "../../components/Common/Breadcrumb"
import "./datatables.scss"
// import axios from "axios"
// import "toastr"
import { useEffect } from "react"
import {
  AddQuery,
  DeleteQuery,
  GetByQuery,
  GetQuery,
  UpdateQuery,
} from "QueryApi/Reactapi"
import SweetAlert from "react-bootstrap-sweetalert"
import axios from "axios"
import Swal from "sweetalert2/dist/sweetalert2.js"
import "sweetalert2/src/sweetalert2.scss"
import Select from "react-select"

import { useUserContext } from "ContextApi/Context"

// import { reset } from "redux-form"
function Marks() {
  const { getclassdata, RoleAllowed, getuserstudent } = useUserContext()
  // console.log(RoleAllowed)
  const [classtoggle, setclasstoggle] = useState(false)
  const [update, setupdate] = useState({})
  const [ClassALERT, setClassALERT] = useState(false)
  const [sid, setsid] = useState("")
  const [deleteID, setdeleteID] = useState("")
  const [getstudents, setgetstudents] = useState([])
  const [Activity, setActivity] = useState([])
  const [selectdata, setselectdata] = useState({
    StudentID: "",
    ActivityID: "",
    Marks: "",
  })
  // const [getuserclass, setgetuserclass] = useState([])
  const handleSTUDENTtoggle = () => {
    setclasstoggle(!classtoggle)
  }

  const handlenewIcon = () => {
    handleSTUDENTtoggle()
    setsid("")
    setupdate("")
  }
  // get data students
  const { data: Marksdata } = GetQuery("/marks", "marks")
  //   console.log(studentdata)

  //get class data from useefect
  useEffect(() => {
    async function onlood() {
      // const { data } = await axios.get(
      //   "https://macalinkaal-production.up.railway.app/student"
      // )
      // // console.log(data)
      // setgetstudents(data)
      const { data: activitydata } = await axios.get(
        "https://macalinkaal-api.vercel.app/activity"
      )
      setActivity(activitydata)
      //   const { data: userstudentdata } = await axios.get(
      //     `http://localhost:2050/student/userbyclass/`
      //   )
      //   setgetuserclass(userstudentdata)
      //   // console.log("userstudentdata", userstudentdata)
    }
    onlood()
  }, [])

  // user class student
  const { data: getuserclass } = GetQuery("/marks/userbyclass", "xasan")

  const optionGroup = [
    {
      // label: "Picnic",
      options: getuserclass?.map(data => {
        return { label: data?.StudentID, value: data?._id }
      }),
    },
  ]
  // console.log(getuserclass)
  // setgetuserclass(darauser)
  // const { data: userstudentdata } = GetByQuery(
  //   "/student/userbyclass",
  //   getuserstudent,
  //   "student"
  // )
  // console.log("getuserstudent", userstudentdata)
  // console.log("getuserstudent", getuserstudent)

  // console.log(classdata)
  // add new class
  const { mutateAsync, isLoading } = AddQuery("/marks", "marks")
  //   //update query
  const { mutateAsync: updatemuate, isLoading: updateloading } = UpdateQuery(
    "/marks",
    sid,
    "marks"
  )

  // post data
  const handleStudendpost = (event, value) => {
    if (sid !== "") {
      // return console.log("upted here")

      // return console.log(value)
      updatemuate(value).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")
        // alert("student has been updated")
        handleSTUDENTtoggle()
        // reset()
      })
    } else {
      // return console.log(selectdata)
      mutateAsync(selectdata).then(res => {
        let message = res?.data?.message
        Swal.fire("success!", message, "success")

        // toastr.success("class created successfully")
        // alert("marks has been  saved successfully")
        handleSTUDENTtoggle()
      })
    }
    // console.log(value)
  }
  // update class
  const updatestudentData = data => {
    console.log(data)
    setupdate(data)
    setsid(data._id)
    handleSTUDENTtoggle()
  }
  // delete class
  const { mutateAsync: deletemuate } = DeleteQuery("/marks", "marks")
  const handledelete = data => {
    setdeleteID(data._id)
    setClassALERT(true)
  }
  const deleteallprice = () => {
    try {
      deletemuate(deleteID).then(res => {
        if (res.data.status == true) {
          Swal.fire("success!", res.data.message, "success")
          setClassALERT(false)
        } else {
          Swal.fire("error!", res.error.message, "error")
        }
        // alert("successfully deleted ")
        // let message = res?.data?.message
      })
    } catch (error) {
      Swal.fire("?", error.message, "error")
    }

    // console.log("first delete")
  }
  const data = {
    columns: [
      {
        label: "StudentName",
        field: "StudentName",
        sort: "asc",
        width: 150,
      },
      {
        label: "ActivitName",
        field: "ActivitName",
        sort: "asc",
        width: 150,
      },
      {
        label: "Marks",
        field: "Marks",
        sort: "asc",
        width: 150,
      },

      {
        label: "Action",
        field: "action",
        sort: "asc",
        width: 150,
      },
    ],
    rows: Marksdata?.map(data => {
      data.StudentName = data.StudentID?.StudentName
      data.ActivitName = data.ActivityID?.Activity

      data.action = (
        <div>
          {RoleAllowed !== "Student" && (
            <button
              className="btn"
              style={{
                // backgroundColor: "#E43825",
                color: "#E43825",
              }}
              onClick={() => updatestudentData(data)}
            >
              {" "}
              <i className="ion ion-md-create font-size-20 m-auto"></i>
            </button>
          )}
          {RoleAllowed !== "Student" && (
            <button
              className="btn   mx-3 "
              style={{
                // backgroundColor: "#E43825",
                color: "#E43825",
                // fontSize: "40px",
              }}
              // disabled={data.Role === "Student" ? "disabled" : "enabled"}
              onClick={() => handledelete(data)}
            >
              {" "}
              <i
                className="ion ion-md-trash font-size-20 m-auto"
                // style={{ fontSize: "40px" }}
              ></i>
            </button>
          )}
        </div>
      )
      return data
    }),
  }
  return (
    <React.Fragment>
      <div className="page-content">
        <MetaTags>
          <title>Class Table | MACALIN KAAL</title>
        </MetaTags>
        <div className="container-fluid">
          <Breadcrumbs
            maintitle="MacalinKaal"
            title="Table"
            breadcrumbItem="Marks"
          />

          <Row className="mt-4">
            <Col className="col-12">
              <Card>
                <CardBody>
                  {/* <CardTitle className="h4">CLASS LIST </CardTitle> */}
                  <div className="row">
                    <div className="col-10 "></div>
                    <div className="col-2">
                      <Row>
                        <Col sm={6} md={4} xl={3}>
                          <div className="my-3 text-center">
                            <button
                              type="button"
                              onClick={() => {
                                handlenewIcon()
                              }}
                              className="btn  waves-effect waves-light"
                              style={{
                                backgroundColor: "#E43825",
                                color: "white",
                              }}
                              data-toggle="modal"
                              data-target="#myModal"
                            >
                              <i className="ion ion-md-add"> </i>
                            </button>
                            <Modal
                              isOpen={classtoggle}
                              toggle={() => {
                                handleSTUDENTtoggle()
                              }}
                            >
                              <div className="modal-header">
                                <h5
                                  className="modal-title mt-0"
                                  id="myModalLabel"
                                >
                                  Modal Heading
                                </h5>
                                <button
                                  type="button"
                                  onClick={() => {
                                    handleSTUDENTtoggle()
                                  }}
                                  className="close"
                                  data-dismiss="modal"
                                  aria-label="Close"
                                >
                                  <span aria-hidden="true">&times;</span>
                                </button>
                              </div>
                              <AvForm
                                className="needs-validation"
                                onValidSubmit={(e, v) => {
                                  handleStudendpost(e, v)
                                }}
                              >
                                <div className="modal-body">
                                  <Row>
                                    <Col md="6">
                                      {sid === "" ? (
                                        <>
                                          {" "}
                                          <Label> Select StudentID</Label>
                                          <Select
                                            name="StudentID"
                                            value={selectdata.value}
                                            onChange={value => {
                                              setselectdata({
                                                ...selectdata,
                                                StudentID: value.value,
                                              })
                                            }}
                                            options={optionGroup}
                                            classNamePrefix="select2-selection"
                                          />
                                        </>
                                      ) : (
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom02">
                                            StudentName
                                          </Label>
                                          <AvField
                                            name="StudentID"
                                            type="select"
                                            value={update.StudentID?._id}
                                            disabled
                                            errorMessage="Enter StudentName"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id="validationCustom02"
                                          >
                                            <option value="">
                                              {" "}
                                              select StudentName
                                            </option>
                                            {getuserclass?.map(useclass => {
                                              return (
                                                <option value={useclass._id}>
                                                  {" "}
                                                  {useclass.StudentName}
                                                </option>
                                              )
                                            })}
                                          </AvField>
                                        </FormGroup>
                                      )}
                                    </Col>

                                    {/* <Col md="6">
                                        <FormGroup className="mb-3">
                                          <Label htmlFor="validationCustom02">
                                            StudentName
                                          </Label>
                                          <AvField
                                            name="StudentID"
                                            type="select"
                                            // value={update.classID}
                                            //   editDefaultValue={update.classID}
                                            //   onChange={e =>
                                            //     setpricepost({
                                            //       ...pricepost,
                                            //       customerID: e.target.value,
                                            //     })
                                            //   }
                                            errorMessage="Enter StudentName"
                                            className="form-control"
                                            validate={{
                                              required: { value: true },
                                            }}
                                            id="validationCustom02"
                                          >
                                            <option value="">
                                              {" "}
                                              select StudentName
                                            </option>
                                            {getstudents.map(student => {
                                              return (
                                                <option value={student._id}>
                                                  {" "}
                                                  {student.StudentName}
                                                </option>
                                              )
                                            })}
                                          </AvField>
                                        </FormGroup>
                                      </Col> */}

                                    {/* <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          StudentName
                                        </Label>
                                        <AvField
                                          name="StudentID"
                                          type="select"
                                          // value={update.classID}
                                          //   editDefaultValue={update.classID}
                                          //   onChange={e =>
                                          //     setpricepost({
                                          //       ...pricepost,
                                          //       customerID: e.target.value,
                                          //     })
                                          //   }
                                          errorMessage="Enter StudentName"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        >
                                          <option value="">
                                            {" "}
                                            select StudentName
                                          </option>
                                          {getstudents.map(student => {
                                            return (
                                              <option value={student._id}>
                                                {" "}
                                                {student.StudentName}
                                              </option>
                                            )
                                          })}
                                        </AvField>
                                      </FormGroup>
                                    </Col> */}
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Activity
                                        </Label>
                                        <AvField
                                          name="ActivityID"
                                          type="select"
                                          value={update.ActivityID?._id}
                                          //   editDefaultValue={update.classID}
                                          onChange={e =>
                                            setselectdata({
                                              ...selectdata,
                                              ActivityID: e.target.value,
                                            })
                                          }
                                          errorMessage="Enter activity details"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        >
                                          <option value="">
                                            {" "}
                                            select Activity details
                                          </option>
                                          {Activity.map(activity => {
                                            return (
                                              <option value={activity._id}>
                                                {" "}
                                                {activity.Activity}
                                              </option>
                                            )
                                          })}
                                        </AvField>
                                      </FormGroup>
                                    </Col>
                                    <Col md="6">
                                      <FormGroup className="mb-3">
                                        <Label htmlFor="validationCustom02">
                                          Marks
                                        </Label>
                                        <AvField
                                          name="Marks"
                                          value={update.Marks}
                                          onChange={e =>
                                            setselectdata({
                                              ...selectdata,
                                              Marks: e.target.value,
                                            })
                                          }
                                          placeholder="enter Marks"
                                          type="number"
                                          errorMessage="Enter Marks"
                                          className="form-control"
                                          validate={{
                                            required: { value: true },
                                          }}
                                          id="validationCustom02"
                                        />
                                      </FormGroup>
                                    </Col>
                                  </Row>
                                </div>
                                <div className="modal-footer">
                                  <button
                                    type="button"
                                    onClick={() => {
                                      handleSTUDENTtoggle()
                                    }}
                                    className="btn  waves-effect"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                    data-dismiss="modal"
                                  >
                                    Close
                                  </button>
                                  <button
                                    type="submit"
                                    disabled={
                                      sid !== "" ? updateloading : isLoading
                                    }
                                    className="btn  waves-effect waves-light"
                                    style={{
                                      backgroundColor: "#E43825",
                                      color: "white",
                                    }}
                                  >
                                    Save changes
                                  </button>
                                </div>
                              </AvForm>
                            </Modal>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>

                  <MDBDataTable responsive bordered data={data} />
                </CardBody>
              </Card>
            </Col>
          </Row>
          {ClassALERT ? (
            <SweetAlert
              title="Are you sure?"
              warning
              showCancel
              confirmButtonText="Yes, delete it!"
              confirmBtnBsStyle="success"
              cancelBtnBsStyle="danger"
              onConfirm={deleteallprice}
              onCancel={() => setClassALERT(false)}
            >
              You won't be able to revert this!
            </SweetAlert>
          ) : null}
        </div>
      </div>
    </React.Fragment>
  )
}

export default Marks
