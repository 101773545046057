import { api } from "ApiConfig/Config"

// ALL client crud operartion
export const GetAllData = async endpoint => {
  return await api.get(endpoint).then(res => res.data)
}
export const GetbyId = async (endpoint, id) => {
  return await api.get(`${endpoint}/${id}`).then(res => res.data)
}
export const AddNewData = async (endpoint, data) => {
  return await api.post(endpoint, data)
}
export const UpdateClientData = async (endpoint, id, data) => {
  // return await api.put(`/${endpoint}/${id}`, data).then((res) => res.data);
  return await api.put(`${endpoint}/${id}`, data)
}
export const DeleteAllData = async (endpoint, id) => {
  return await api.delete(`${endpoint}/${id}`)
}
