import React from "react"
import ReactDOM from "react-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { BrowserRouter } from "react-router-dom"
import "./i18n"
import { Provider } from "react-redux"
import { QueryClient, QueryClientProvider } from "@tanstack/react-query"
// import { ToastContainer } from "react-toastify"
// import "react-toastify/dist/ReactToastify.css"
import store from "./store"
import { UserContextProvider } from "ContextApi/Context"
const queryClient = new QueryClient()

const app = (
  <Provider store={store}>
    <QueryClientProvider client={queryClient}>
      <BrowserRouter>
        <UserContextProvider>
          <App />
        </UserContextProvider>
        {/* <ToastContainer /> */}
      </BrowserRouter>
    </QueryClientProvider>
  </Provider>
)

ReactDOM.render(app, document.getElementById("root"))
serviceWorker.unregister()
